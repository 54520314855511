<template>
    <a-stepper :slots="3" class="h-screen-fixed flex max-w-5xl mx-auto flex-col z-5 overflow-y-scroll">

      <template #header>
        <div class="p-2">
          <a-logo-switch class="p-2 h-12" />
        </div>
      </template>

      <template #step-1>
        <div class="flex-grow flex justify-center items-center p-4">
          <div class="flex flex-col space-y-20">
            <h1 class="text-2xl leading-8 text-black font-thin text-left">
              <span class="font-normal">Descubre</span> y deja que te <span class="text-primary">descubran</span>
            </h1>
            <h4 class="text-left text-xl font-thin">
              La manera más fácil de conectar con restaurantes, ser parte de aperturas y experiencias
            </h4>
          </div>
        </div>
      </template>

      <template #step-2>
        <div class="flex-grow">
          asda 2
        </div>
      </template>

      <template #step-3>
        <div class="flex-grow">
          asda 2
        </div>
      </template>

      <template #footer="{ index, goNext }">
        <div class="flex justify-between p-6">
          <span class="flex space-x-1">
            <span v-for="n in 3" :key="n" :class="['inline-block transition transition-all h-3 rounded-full', {
              'w-6 bg-primary': index === n,
              'w-3 bg-gray-200': index !== n
            }]" />
          </span>
          <button class="text-primary" @click="goNext">
            Next
          </button>
        </div>
      </template>
    </a-stepper>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'
export default {
  setup () {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--h-screen-fixed', `${window.innerHeight}px`)
    }

    onMounted(() => {
      window.addEventListener('resize', appHeight)
      appHeight()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', appHeight)
    })
  }
}
</script>

<style>
:root {
  --h-screen-fixed: 100%;
}
.h-screen-fixed {
  height: 100vh;
  height: var(--h-screen-fixed)
}
</style>
